import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import dynamic from 'next/dynamic';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

// Scenes
import { EVENT_ATTRIBUTE, EVENT_NAME, useMoengageEventTracker } from '../../hooks/useMoengage';

import {
  changeCheckoutLoading,
  changeScene,
  purchaseCourse,
  setCurrentPackage,
  validatePincode,
} from '../../redux/slices/checkoutSlice';

import { javaStoreApi } from '../../utils/AxiosInstance';
import { getCourseType, objToCamelCase } from '../../utils/helper';

import styles from './BuyNow.module.scss';

const CheckoutScene = dynamic(() => import('./Scene/Checkout'));
const PackageScene = dynamic(() => import('./Scene/Package'));
const AddressScene = dynamic(() => import('./Scene/Address'));
const PreCheckoutScene = dynamic(() => import('./Scene/PreCheckout'));
const BuyNowErrorBoundary = dynamic(() => import('./ErrorBoundary'));

function BuyNowPortalComponent(props) {
  const wrapperId = 'buy-now-portal';

  const [portalElement, setPortalElement] = React.useState(null);

  const createWrapperAndAppendToBody = (elementId) => {
    const element = document.createElement('div');
    element.setAttribute('id', elementId);
    document.body.appendChild(element);
    return element;
  };

  React.useEffect(() => {
    let element = document.getElementById(wrapperId);
    let portalCreated = false;
    // if an element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId);
      portalCreated = true;
    }

    setPortalElement(element);

    // cleaning up the portal element
    return () => {
      // delete the programmatically created element
      if (portalCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  // portalElement state will be null on the very first render.
  if (!portalElement) return null;

  return createPortal(props?.children, portalElement);
}

function BuyNowForm({
  scene,
  dispatch,
  isLoading,
  authProfile,
  courseDetail: {
    courseId, languageId, packageId, bookLanguageId,
    orderInitiateId, checkoutAction, couponCode,
    courseTypeId, addOnBookLanguageId, emiEnabled = false,
  },
  crmOrderInitiate,
  currentPackage,
  coursePurchaseDetail,
  modeData,
}) {
  const form = useForm({
    defaultValues: {
      emiEnabled,
      languageId,
      bookLanguageId,
      orderInitiateId,
      addOnBookLanguageId,
      coupon: couponCode,
    },
  });

  const [courseInfo, setCourseInfo] = React.useState(null);
  const [packages, setPackages] = React.useState([]);
  const trackMoengageEvent = useMoengageEventTracker();

  const watchAddressPincode = form.watch('address.pinCode');

  React.useEffect(() => {
    if (watchAddressPincode && watchAddressPincode?.length === 6) {
      dispatch(validatePincode({ pinCode: watchAddressPincode }))
        .then(({ payload }) => {
          if (payload.success) {
            const addressDetails = payload.data;
            form.setValue('address.city', addressDetails?.district, { shouldValidate: true });
            form.setValue('address.state', addressDetails?.state, { shouldValidate: true });
          } else {
            form.setValue('address.city', '', { shouldValidate: true });
            form.setValue('address.state', '', { shouldValidate: true });
            form.setError('address.pinCode', { type: 'inValidPin', message: 'Invalid Pincode' });
          }
        });
    }
  }, [form, dispatch, watchAddressPincode]);

  // Set Address Form Default Values
  React.useEffect(() => {
    const addressValuePresent = form.getValues('address');
    let addressField = null;
    if ((authProfile) && form.setValue && !addressValuePresent?.name && !crmOrderInitiate?.orderData?.user_address) {
      if (!crmOrderInitiate?.orderData?.user_detail?.user_id) {
        // crm case
        addressField = {
          ...authProfile,
          fullName: `${authProfile.firstName || ''} ${authProfile.lastName || ''}`.trim(),
        };
      }
    } else if (crmOrderInitiate?.orderData?.user_address && !addressValuePresent?.name) {
      const crmAddress = crmOrderInitiate?.orderData?.user_address;
      addressField = {};
      addressField.fullName = crmAddress?.fullname;
      addressField.mobileNumber = crmAddress?.mobile;
      addressField.email = crmAddress?.emailaddress;
      addressField.address = crmAddress?.address;
      addressField.area = crmAddress?.area;
      addressField.landmark = crmAddress?.landmark;
      addressField.city = crmAddress?.city;
      addressField.state = crmAddress?.state;
      addressField.pincode = crmAddress?.pincode;
    }
    if (addressField) {
      form.setValue('address.name', addressField?.fullName);
      form.setValue('address.phone', addressField.mobileNumber);
      form.setValue('address.email', addressField.email);
      form.setValue('address.address', addressField.address);
      form.setValue('address.area', addressField.area);
      form.setValue('address.landmark', addressField.landmark);
      form.setValue('address.city', addressField.city);
      form.setValue('address.state', addressField.state);
      form.setValue('address.pinCode', addressField.pincode);
    }
  }, [authProfile, form, crmOrderInitiate]);

  React.useEffect(() => {
    if (courseId && languageId && checkoutAction) {
      form.setValue('courseId', courseId);
      form.setValue('languageId', languageId);
      form.setValue('checkoutAction', checkoutAction);

      (async () => {
        try {
          dispatch(changeCheckoutLoading(true));
          const payload = {
            courseId,
            languageId,
            action: checkoutAction,
          };
          if (modeData?.mode === 'offline' && modeData?.location) {
            payload.offlineCentreId = parseInt(modeData.location, 10) || '';
          }
          const response = await javaStoreApi.get('api/v1/course/package/details', {
            params: payload,
          });

          const { success, data } = response.data;
          if (success) {
            // eslint-disable-next-line no-shadow
            const { courseTypeId, packages, ...courseInfo } = data;
            form.setValue('courseTypeId', courseTypeId);

            setPackages(packages);
            setCourseInfo({ ...courseInfo, courseType: getCourseType(courseTypeId) });
          }
        } catch (e) {
          setPackages([]);
        } finally {
          setTimeout(() => dispatch(changeCheckoutLoading(false)), 100);
        }
      })();
    } else {
      form.reset();
    }
  }, [checkoutAction, courseId, dispatch, form, languageId]);

  React.useEffect(() => {
    if (packages.length > 0 && !currentPackage) {
      let selectedPackage = null;
      if (crmOrderInitiate?.orderData?.user_detail?.user_id && !currentPackage?.packageId) {
        // crm case
        selectedPackage = packages.find((_package) => _package.packageId ===
          crmOrderInitiate?.orderData?.course_package_detail?.package_id);
      } else if (coursePurchaseDetail?.packageId) {
        selectedPackage = packages.find((_package) => _package.packageId === coursePurchaseDetail.packageId);
      } else if (packages.length) {
        selectedPackage = packages.find((_package) => _package.bestSelling);
        selectedPackage = selectedPackage || packages[0];
      }

      if (coursePurchaseDetail?.packageId) {
        selectedPackage = packages.find((_package) => _package.packageId === coursePurchaseDetail.packageId);
      } else if (packageId) {
        selectedPackage = packages.find((_package) => _package.packageId === packageId);
      } else if (packages.length) {
        selectedPackage = packages.find((_package) => _package.bestSelling);
        selectedPackage = selectedPackage || packages[0];
      }

      // eslint-disable-next-line no-unused-expressions
      selectedPackage && dispatch(setCurrentPackage(selectedPackage));
    }
  }, [coursePurchaseDetail, packages, dispatch, currentPackage, packageId]);

  const onCheckout = React.useCallback(({ submitAction, ...values }) => {
    if (submitAction === 'course_purchase') {
      // eslint-disable-next-line no-shadow
      const { packageId, coupon, emiEnabled, courseTypeId, checkoutAction } = values;

      // eslint-disable-next-line no-shadow
      let couponCode = coupon;
      if (crmOrderInitiate?.orderData?.course_detail?.coupon_code && !coupon) {
        couponCode = crmOrderInitiate?.orderData?.course_detail?.coupon_code;
        form.setValue('coupon', couponCode);
      }

      if (values.coupon !== values.manualCoupon && values.manualOrAuto === 'manual') {
        couponCode = '';
      }

      const addressValuePresent = form.getValues('address');
      dispatch(
        purchaseCourse({
          courseId,
          packageId,
          couponCode,
          userAddress: addressValuePresent,
          languageId,
          courseTypeId,
          paymentType: emiEnabled ? 2 : 1,
          checkoutAction,
          modeData,
        }),
        // eslint-disable-next-line no-shadow
      ).then(({ payload }) => {
        if (payload) {
          try {
            trackMoengageEvent(EVENT_NAME.FINAL_BUY_NOW_TAPPED, {
              [EVENT_ATTRIBUTE.COURSE_ID]: courseId,
              [EVENT_ATTRIBUTE.COUPON_CODE]: payload?.couponCode || '',
              [EVENT_ATTRIBUTE.DISCOUNT_AMOUNT]: payload?.couponDiscount || '',
              [EVENT_ATTRIBUTE.FINAL_PRICE]: payload?.sellingPrice,
              [EVENT_ATTRIBUTE.PACKAGE_NAME]: payload?.packageName,
              [EVENT_ATTRIBUTE.PAYMENT_TYPE]: payload?.paymentType,
            });
          } catch (error) {
            // Handle Error
          }
        }
        if (!payload?.orderInitiateId) {
          return;
        }

        if (payload?.bookLanguages?.length > 0 && scene === 'PACKAGE' && getCourseType(courseTypeId) !== 'BOOK') {
          dispatch(changeScene('BOOK_PACKAGE'));
        } else if (!crmOrderInitiate?.orderData?.user_detail?.user_id && payload?.addonStatus) {
          dispatch(changeScene('PRE_CHECKOUT'));
        } else {
          dispatch(changeScene('CHECKOUT'));
        }
      });
    }
  }, [
    courseId, dispatch, form, languageId, scene,
    crmOrderInitiate?.orderData?.user_detail?.user_id,
    crmOrderInitiate?.orderData?.course_detail?.coupon_code,
  ]);

  React.useEffect(() => {
    if (orderInitiateId) {
      const addressValuePresent = form.getValues('address');
      dispatch(
        purchaseCourse({
          courseId,
          packageId,
          couponCode,
          languageId,
          courseTypeId,
          userAddress: addressValuePresent,
          paymentType: emiEnabled ? 2 : 1,
          checkoutAction,
          addOnBookLanguageId,
          modeData,
        }),
      ).then(() => dispatch(changeScene('CHECKOUT')));
    }
  }, [
    checkoutAction, couponCode, courseId, courseTypeId,
    dispatch, emiEnabled, languageId, orderInitiateId, packageId,
    addOnBookLanguageId,
  ]);

  return (
    <FormProvider {...form}>
      <form className={styles.contentWrapper} onSubmit={form.handleSubmit(onCheckout)}>
        {scene === 'PACKAGE' && <PackageScene courseInfo={courseInfo} coursePackages={packages} />}
        {scene === 'BOOK_PACKAGE' && <PackageScene courseInfo={courseInfo} packageType="BOOK" />}
        {scene === 'ADDRESS' && <AddressScene courseInfo={courseInfo} />}
        {scene === 'PRE_CHECKOUT' && <PreCheckoutScene />}
        {scene === 'CHECKOUT' && <CheckoutScene courseInfo={courseInfo} checkoutAction={checkoutAction} />}

        <div className={classNames(styles.loadingWrapper, { [styles.enable]: isLoading })}>
          <FontAwesomeIcon icon={faCircleNotch} color="#423DCF" spin />
        </div>
      </form>
    </FormProvider>
  );
}

function BuyNowContainer(props) {
  // eslint-disable-next-line react/destructuring-assignment
  const { checkoutAction } = props.courseDetail;

  return (
    <BuyNowPortalComponent>
      <div
        className={classNames(styles.wrapper, {
          [styles.isVisible]: checkoutAction,
        })}
      >
        <BuyNowErrorBoundary>
          {checkoutAction && <BuyNowForm {...props} />}
        </BuyNowErrorBoundary>
      </div>
    </BuyNowPortalComponent>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.checkout.loading,
  scene: state.checkout.currentScene,
  authProfile: objToCamelCase(state.auth.authUser),
  courseDetail: state.checkout.currentCourse,
  currentPackage: state.checkout.currentPackage,
  crmOrderInitiate: state.auth.crmOrderInitiate,
  coursePurchaseDetail: state.checkout.coursePurchase.detail,
  modeData: state.course.modeData,
});

export default connect(mapStateToProps)(BuyNowContainer);
